const moment =  require ("moment-timezone");

let appName = "Safety"

let risks = {
    'Severe':1,
    'Moderate':2,
    'Low':3,
}

let Role = {
    ALL: 'All',
    ADMIN:'Administrator',
    TRAFFIC_ENG: 'Traffic Engineer'
}

let Apps = {
    SAFETY: 'safety',
    PAX:'pax'
}

function calculateSimpleRisk(risksArray){

    console.log('Calculate simple risk: ', risksArray)

    if(risksArray && risksArray.length > 0){
        let ratingsMap = new Map();

        //Sum values
        risksArray.forEach(rating => {
            if(rating){
                if (ratingsMap.has(`${rating}`)) {
                    ratingsMap.set(`${rating}`, ratingsMap.get(`${rating}`) + 1)
                } else {
                    ratingsMap.set(`${rating}`, 1)
                }
            }

        })

        console.log('Calculate simple risk map', ratingsMap)

        if(ratingsMap.size > 0){
            const max = [...ratingsMap.entries()].reduce((a, e ) => e[1] > a[1] ? e : a)

            console.log('Calculate simple risk max', max)
            if(max.length > 0){
                return capitalizeFirstLetter(max[0])
            }
        }

    }

    return '-'
}

function calculateRiskSumFromWeeklyReport(weeklyReport){

    if(weeklyReport && weeklyReport.length > 0){
        let ratings = weeklyReport.map(report => {return report.severityRating})
        let ratingsMap = new Map();
        ratingsMap.set('low', 0)
        ratingsMap.set('moderate', 0)
        ratingsMap.set('severe', 0)

        //Sum values
        if(ratings && ratings.length > 0) {
            ratings.forEach(rating => {
                if(rating && rating.length > 1){
                    ratingsMap.set(`${rating[0]}`, ratingsMap.get(`${rating[0]}`) + rating[1])
                }
            })

            console.log('Weekly severity ratings calc', ratingsMap)

            const max = [...ratingsMap.entries()].reduce((a, e) => e[1] > a[1] ? e : a)

            console.log('Weekly severity ratings max', max)
            if (max.length > 0) {
                return capitalizeFirstLetter(max[0])
            }
        }
    }

    return '-'
}

function calculateToDcaFromWeeklyReport(weeklyReport){
    if(weeklyReport && weeklyReport.length > 0) {
        let topDca = weeklyReport.map(report => {
            return report.topDCA
        })
        console.log('Top dca:', topDca)
        let topDcaMap = new Map();

        if(topDca && topDca.length > 0){
            //Sum values
            topDca.forEach(tpDca => {
                if(tpDca && tpDca.length > 1){
                    if (topDcaMap.has(`${tpDca[0]}`)) {
                        topDcaMap.set(`${tpDca[0]}`, topDcaMap.get(`${tpDca[0]}`) + tpDca[1])
                    } else {
                        topDcaMap.set(`${tpDca[0]}`, tpDca[1])
                    }
                }

            })

            console.log('Top dca map:', topDcaMap)
            if(topDcaMap.size > 0){
                const max = [...topDcaMap.entries()].reduce((a, e) => e[1] > a[1] ? e : a)

                console.log('Top DCA max', max)
                if (max.length > 0) {
                    return max[0]
                }
            }

        }
    }
    return '-'
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getRiskRatingClass(property) {
    return {
        'severe': property === 'Severe',
        'high': property === 'Moderate',
        'low': property === 'Low'
    }
}

function getSeverityRatingScore(property) {
    if(property === 'severe'){
        return 90
    }else if(property === 'moderate'){
        return 40
    }else {
        return 10
    }
}

function getMarkerClass(item, selectedDataDevice) {
    if (item.riskRating === 'Severe') {
        return selectedDataDevice != null && selectedDataDevice.id === item.id ? 'oval-marker-severe oval-marker-large-selected' : 'oval-marker-severe'
    } else if (item.riskRating === 'Low') {
        return selectedDataDevice != null && selectedDataDevice.id === item.id ? 'oval-marker-low oval-marker-small-selected' : 'oval-marker-low'
    } else if (item.riskRating === 'Moderate') {
        return selectedDataDevice != null && selectedDataDevice.id === item.id ? 'oval-marker-medium oval-marker-small-selected' : 'oval-marker-medium'
    }else{
        return selectedDataDevice != null && selectedDataDevice.id === item.id ? 'oval-marker-no-data oval-marker-small-selected' : 'oval-marker-no-data'
    }
}


function getDateISOIgnoreTimezone(date) {
    let dateUtc = moment.utc(date);
    let localDate = moment(dateUtc).local();

    return localDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
}

function getDate(date) {
    let dateUtc = moment.utc(date);
    //let localDate = moment(dateUtc).local();
    let localDate = moment.tz(dateUtc, "Australia/Melbourne");

    return localDate.format('DD-MM-YYYY')
}

function getShortDate(date) {
    let dateUtc = moment.utc(date);
    //let localDate = moment(dateUtc).local();
    let localDate = moment.tz(dateUtc, "Australia/Melbourne");

    return localDate.format('DD.MM.YYYY.')
}

function getTime(date){
    let dateUtc = moment.utc(date);
    //let localTime = moment(dateUtc).local();
    let localTime = moment.tz(dateUtc, "Australia/Melbourne");

    return localTime.format('HH:mm:ss')
}

function getShortTime(date) {
    let dateUtc = moment.utc(date);
    //let localTime = moment(dateUtc).local();
    let localTime = moment.tz(dateUtc, "Australia/Melbourne");

    return localTime.format('HH:mm')
}

function getFormattedHours() {
    return [
        '00:00-01:00',
        '01:00-02:00',
        '02:00-03:00',
        '03:00-04:00',
        '04:00-05:00',
        '05:00-06:00',
        '06:00-07:00',
        '07:00-08:00',
        '08:00-09:00',
        '09:00-10:00',
        '10:00-11:00',
        '11:00-12:00',
        '12:00-13:00',
        '13:00-14:00',
        '14:00-15:00',
        '15:00-16:00',
        '16:00-17:00',
        '17:00-18:00',
        '18:00-19:00',
        '19:00-20:00',
        '20:00-21:00',
        '21:00-22:00',
        '22:00-23:00',
        '23:00-00:00'
    ]
}

function getFormatted15Minutes() {
    return {
        '00:00': 0,
        '00:15': 0,
        '00:30': 0,
        '00:45': 0,
        '01:00': 0,
        '01:15': 0,
        '01:30': 0,
        '01:45': 0,
        '02:00': 0,
        '02:15': 0,
        '02:30': 0,
        '02:45': 0,
        '03:00': 0,
        '03:15': 0,
        '03:30': 0,
        '03:45': 0,
        '04:00': 0,
        '04:15': 0,
        '04:30': 0,
        '04:45': 0,
        '05:00': 0,
        '05:15': 0,
        '05:30': 0,
        '05:45': 0,
        '06:00': 0,
        '06:15': 0,
        '06:30': 0,
        '06:45': 0,
        '07:00': 0,
        '07:15': 0,
        '07:30': 0,
        '07:45': 0,
        '08:00': 0,
        '08:15': 0,
        '08:30': 0,
        '08:45': 0,
        '09:00': 0,
        '09:15': 0,
        '09:30': 0,
        '09:45': 0,
        '10:00': 0,
        '10:15': 0,
        '10:30': 0,
        '10:45': 0,
        '11:00': 0,
        '11:15': 0,
        '11:30': 0,
        '11:45': 0,
        '12:00': 0,
        '12:15': 0,
        '12:30': 0,
        '12:45': 0,
        '13:00': 0,
        '13:15': 0,
        '13:30': 0,
        '13:45': 0,
        '14:00': 0,
        '14:15': 0,
        '14:30': 0,
        '14:45': 0,
        '15:00': 0,
        '15:15': 0,
        '15:30': 0,
        '15:45': 0,
        '16:00': 0,
        '16:15': 0,
        '16:30': 0,
        '16:45': 0,
        '17:00': 0,
        '17:15': 0,
        '17:30': 0,
        '17:45': 0,
        '18:00': 0,
        '18:15': 0,
        '18:30': 0,
        '18:45': 0,
        '19:00': 0,
        '19:15': 0,
        '19:30': 0,
        '19:45': 0,
        '20:00': 0,
        '20:15': 0,
        '20:30': 0,
        '20:45': 0,
        '21:00': 0,
        '21:15': 0,
        '21:30': 0,
        '21:45': 0,
        '22:00': 0,
        '22:15': 0,
        '22:30': 0,
        '22:45': 0,
        '23:00': 0,
        '23:15': 0,
        '23:30': 0,
        '23:45': 0
    }
}

function getFormattedHourData() {
    return {
        '00:00': 0,
        '01:00': 0,
        '02:00': 0,
        '03:00': 0,
        '04:00': 0,
        '05:00': 0,
        '06:00': 0,
        '07:00': 0,
        '08:00': 0,
        '09:00': 0,
        '10:00': 0,
        '11:00': 0,
        '12:00': 0,
        '13:00': 0,
        '14:00': 0,
        '15:00': 0,
        '16:00': 0,
        '17:00': 0,
        '18:00': 0,
        '19:00': 0,
        '20:00': 0,
        '21:00': 0,
        '22:00': 0,
        '23:00': 0
    }
}

function getHoursList() {
    return [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
    ]
}

function getSpeedDistributionData() {
    return {
        '0-5': '2',
        '5-10': '3',
        '10-15': '4',
        '15-20': '5',
        '20-25': '6',
        '25-30': '8',
        '35-40': '12',
        '40-45': '15',
        '45-50': '20',
        '50-55': '21',
        '55-60': '23',
        '60-65': '22',
        '65-70': '20',
        '70-75': '18',
        '75-80': '13',
        '85-85': '10',
        '85-90': '2',
    }
}


function getClassTypes() {
    return [
        {
            name: 'Car',
            icon: 'car'
        },
        {
            name: 'Bus',
            icon: 'bus-side'
        },
        {
            name: 'Truck',
            icon: 'truck'
        },
        {
            name: 'Bike',
            icon: 'bike'
        },
        {
            name: 'Pedestrian',
            icon: 'walk'
        },
        {
            name: 'Articulated Trucks',
            icon: 'dump-truck'
        },
        {
            name: 'Rigid Trucks',
            icon: 'truck-cargo-container'
        },
        {
            name: 'Light Vehicles',
            icon: 'car-hatchback'
        },
        {
            name: 'Tram',
            icon: 'tram'
        },
        {
            name: 'Preschool',
            icon: 'teddy-bear'
        },
        {
            name: 'Primary',
            icon: 'human-male-child'
        },
        {
            name: 'Secondary',
            icon: 'account-school'
        },
        {
            name: 'Adult',
            icon: 'human-handsdown'
        },
        {
            name: 'Super visor',
            icon: 'shield-account'
        }
    ]
}

function getSubClassType(classType){
    let vehicles = ['Car','Bus','Truck','Articulated Trucks','Rigid Trucks','Light Vehicles','Tram']
    let pedestrians = ['Pedestrian','Preschool','Primary','Secondary','Adult', 'Super visor']

    if(classType === 'Bike'){
        return 'cycles'
    }else if(vehicles.includes(classType)){
        return 'vehicles'
    }else if(pedestrians.includes(classType)){
        return 'pedestrians'
    }
}

function getMatrixReport(report){

    /*            Car:{severe:0, moderate:0, low: 0},


            Light_commercial:{severe:0, moderate:0, low: 0},
            Heavy_commercial:{severe:0, moderate:0, low: 0},
            Adult:{severe:0, moderate:0, low: 0},
            Student:{severe:0, moderate:0, low: 0},
            Child:{severe:0, moderate:0, low: 0},
            Bike:{severe:0, moderate:0, low: 0},
            Motorcycle:{severe:0, moderate:0, low: 0},*/

    let matrixReport = JSON.parse(JSON.stringify(report))
    console.log('Initial report', report)
/*    delete matrixReport.Passenger
    delete matrixReport.Bus

    let severeAdult = matrixReport.Adult.Adult.severe + matrixReport.Adult.Student.severe + matrixReport.Adult.Child.severe
    let moderateAdult = matrixReport.Adult.Adult.moderate + matrixReport.Adult.Student.moderate + matrixReport.Adult.Child.moderate
    let lowAdult = matrixReport.Adult.Adult.low + matrixReport.Adult.Student.low + matrixReport.Adult.Child.low

    let severeStudent = matrixReport.Student.Adult.severe + matrixReport.Student.Student.severe + matrixReport.Student.Child.severe
    let moderateStudent = matrixReport.Student.Adult.moderate + matrixReport.Student.Student.moderate + matrixReport.Student.Child.moderate
    let lowStudent = matrixReport.Student.Adult.low + matrixReport.Student.Student.low + matrixReport.Student.Child.low

    let severeChild = matrixReport.Child.Adult.severe + matrixReport.Child.Student.severe + matrixReport.Child.Child.severe
    let moderateChild = matrixReport.Child.Adult.moderate + matrixReport.Child.Student.moderate + matrixReport.Child.Child.moderate
    let lowChild = matrixReport.Child.Adult.low + matrixReport.Child.Student.low + matrixReport.Child.Child.low

    matrixReport.Pedestrian = {Pedestrian:{severe: 0, moderate: 0, low:0}}





    delete matrixReport.Adult
    delete matrixReport.Student
    delete matrixReport.Child*/

    return matrixReport
}

function getMapData() {
    return {
        hourlyReport: {
            '00:15': {
                weekdaysAverage: {
                    12: {
                        Truck: 2.3, //23 for 10 days
                        Car: 15, //150 for 10 days
                        Pedestrian: 12 //120 for 10 days
                    },
                    13: {
                        Truck: 2.3,
                        Car: 11,
                        Pedestrian: 10
                    }
                },
                weekendAverage: {
                    12: {
                        Truck: 3.0, //12 for 4 days
                        Car: 37.5, //150 for 4 days
                        Pedestrian: 30.0 //120 for 4 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
                averageDailyTraffic: {
                    12: {
                        Truck: 1.78, //25 for 14 days
                        Car: 10.7, //150 for 14 days
                        Pedestrian: 8.5 //120 for 14 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
            },
            '00:30': {
                weekdaysAverage: {
                    12: {
                        Truck: 2.3, //23 for 10 days
                        Car: 15, //150 for 10 days
                        Pedestrian: 12 //120 for 10 days
                    },
                    13: {
                        Truck: 12,
                        Car: 11,
                        Pedestrian: 10
                    }
                },
                weekendAverage: {
                    12: {
                        Truck: 3.0, //12 for 4 days
                        Car: 37.5, //150 for 4 days
                        Pedestrian: 30.0 //120 for 4 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
                averageDailyTraffic: {
                    12: {
                        Truck: 1.78, //25 for 14 days
                        Car: 10.7, //150 for 14 days
                        Pedestrian: 8.5 //120 for 14 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
            },
            '00:45': {
                weekdaysAverage: {
                    12: {
                        Truck: 2.3, //23 for 10 days
                        Car: 15, //150 for 10 days
                        Pedestrian: 12 //120 for 10 days
                    },
                    13: {
                        Truck: 12,
                        Car: 11,
                        Pedestrian: 10
                    }
                },
                weekendAverage: {
                    12: {
                        Truck: 3.0, //12 for 4 days
                        Car: 37.5, //150 for 4 days
                        Pedestrian: 30.0 //120 for 4 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
                averageDailyTraffic: {
                    12: {
                        Truck: 1.78, //25 for 14 days
                        Car: 10.7, //150 for 14 days
                        Pedestrian: 8.5 //120 for 14 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
            },
            '01:00': {
                weekdaysAverage: {
                    12: {
                        Truck: 2.3, //23 for 10 days
                        Car: 15, //150 for 10 days
                        Pedestrian: 12 //120 for 10 days
                    },
                    13: {
                        Truck: 12,
                        Car: 11,
                        Pedestrian: 10
                    }
                },
                weekendAverage: {
                    12: {
                        Truck: 3.0, //12 for 4 days
                        Car: 37.5, //150 for 4 days
                        Pedestrian: 30.0 //120 for 4 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
                averageDailyTraffic: {
                    12: {
                        Truck: 1.78, //25 for 14 days
                        Car: 10.7, //150 for 14 days
                        Pedestrian: 8.5 //120 for 14 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
            },
            '01:15': {
                weekdaysAverage: {
                    12: {
                        Truck: 2.3, //23 for 10 days
                        Car: 15, //150 for 10 days
                        Pedestrian: 12 //120 for 10 days
                    },
                    13: {
                        Truck: 12,
                        Car: 11,
                        Pedestrian: 10
                    }
                },
                weekendAverage: {
                    12: {
                        Truck: 3.0, //12 for 4 days
                        Car: 37.5, //150 for 4 days
                        Pedestrian: 30.0 //120 for 4 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
                averageDailyTraffic: {
                    12: {
                        Truck: 1.78, //25 for 14 days
                        Car: 10.7, //150 for 14 days
                        Pedestrian: 8.5 //120 for 14 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
            },
            '01:30': {
                weekdaysAverage: {
                    12: {
                        Truck: 2.3, //23 for 10 days
                        Car: 15, //150 for 10 days
                        Pedestrian: 12 //120 for 10 days
                    },
                    13: {
                        Truck: 12,
                        Car: 11,
                        Pedestrian: 10
                    }
                },
                weekendAverage: {
                    12: {
                        Truck: 3.0, //12 for 4 days
                        Car: 37.5, //150 for 4 days
                        Pedestrian: 30.0 //120 for 4 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
                averageDailyTraffic: {
                    12: {
                        Truck: 1.78, //25 for 14 days
                        Car: 10.7, //150 for 14 days
                        Pedestrian: 8.5 //120 for 14 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
            },
            '01:45': {
                weekdaysAverage: {
                    12: {
                        Truck: 2.3, //23 for 10 days
                        Car: 15, //150 for 10 days
                        Pedestrian: 12 //120 for 10 days
                    },
                    13: {
                        Truck: 12,
                        Car: 11,
                        Pedestrian: 10
                    }
                },
                weekendAverage: {
                    12: {
                        Truck: 3.0, //12 for 4 days
                        Car: 37.5, //150 for 4 days
                        Pedestrian: 30.0 //120 for 4 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
                averageDailyTraffic: {
                    12: {
                        Truck: 1.78, //25 for 14 days
                        Car: 10.7, //150 for 14 days
                        Pedestrian: 8.5 //120 for 14 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
            },
            '02:00': {
                weekdaysAverage: {
                    12: {
                        Truck: 2.3, //23 for 10 days
                        Car: 15, //150 for 10 days
                        Pedestrian: 12 //120 for 10 days
                    },
                    13: {
                        Truck: 12,
                        Car: 11,
                        Pedestrian: 10
                    }
                },
                weekendAverage: {
                    12: {
                        Truck: 3.0, //12 for 4 days
                        Car: 37.5, //150 for 4 days
                        Pedestrian: 30.0 //120 for 4 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
                averageDailyTraffic: {
                    12: {
                        Truck: 1.78, //25 for 14 days
                        Car: 10.7, //150 for 14 days
                        Pedestrian: 8.5 //120 for 14 days
                    },
                    13: {
                        Truck: 2.7,
                        Car: 11.2,
                        Pedestrian: 10.5
                    }
                },
            },
        },
        volumeReport: {
            adt: {
                fifteenMinutes: {
                    '00:00 - 00:15': {
                        12: {
                            Truck: 2.3, //23 for 10 days
                            Car: 15, //150 for 10 days
                            Pedestrian: 12 //120 for 10 days
                        },
                        13: {
                            Truck: 12,
                            Car: 11,
                            Pedestrian: 10
                        }
                    },
                    '00:15 - 00:30': {
                        12: {
                            Truck: 2.3, //23 for 10 days
                            Car: 15, //150 for 10 days
                            Pedestrian: 12 //120 for 10 days
                        },
                        13: {
                            Truck: 12,
                            Car: 11,
                            Pedestrian: 10
                        }
                    },
                },
                hourly: {
                    '00:00 - 01:00': {
                        12: {
                            Truck: 2.3, //23 for 10 days
                            Car: 15, //150 for 10 days
                            Pedestrian: 12 //120 for 10 days
                        },
                        13: {
                            Truck: 12,
                            Car: 11,
                            Pedestrian: 10
                        }
                    },
                    '01:00 - 02:00': {
                        12: {
                            Truck: 2.3, //23 for 10 days
                            Car: 15, //150 for 10 days
                            Pedestrian: 12 //120 for 10 days
                        },
                        13: {
                            Truck: 12,
                            Car: 11,
                            Pedestrian: 10
                        }
                    },
                }
            },
            date: {
                '21.03.2024.': {
                    fifteenMinutes: {
                        '00:00 - 00:15': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                        '00:15 - 00:30': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                    },
                    hourly: {
                        '00:00 - 01:00': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                        '01:00 - 02:00': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                    }
                },
                '22.03.2024.': {
                    fifteenMinutes: {
                        '00:00 - 00:15': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                        '00:15 - 00:30': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                    },
                    hourly: {
                        '00:00 - 01:00': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                        '01:00 - 02:00': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                    }
                },
            }
        },
        speedReport: {
            adt: {
                fifteenMinutes: {
                    '00:00 - 00:15': {
                        12: {
                            speed: 50,
                        },
                        13: {
                            speed: 45
                        }
                    },
                    '00:15 - 00:30': {
                        12: {
                            speed: 50,
                        },
                        13: {
                            speed: 50,
                        }
                    },
                },
                hourly: {
                    '00:00 - 01:00': {
                        12: {
                            speed: 50,
                        },
                        13: {
                            speed: 50,
                        }
                    },
                    '01:00 - 02:00': {
                        12: {
                            speed: 50,
                        },
                        13: {
                            speed: 50,
                        }
                    },
                }
            },
            date: {
                '21.03.2024.': {
                    fifteenMinutes: {
                        '00:00 - 00:15': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                        '00:15 - 00:30': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                    },
                    hourly: {
                        '00:00 - 01:00': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                        '01:00 - 02:00': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                    }
                },
                '22.03.2024.': {
                    fifteenMinutes: {
                        '00:00 - 00:15': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                        '00:15 - 00:30': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                    },
                    hourly: {
                        '00:00 - 01:00': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                        '01:00 - 02:00': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                    }
                },
            }
        },
        speedDistributionReport: {
            '0-5': 7,
            '5-10': 8,
            '10-15': 12,
            '15-20': 15,
            '20-25': 17,
            '25-30': 22,
            '30-35': 30,
            '35-40': 45,
            '40-45': 45,
            '45-50': 47,
            '50-55': 49,
            '55-60': 35,
            '60-65': 32,
            '65-70': 20,
            '70-75': 12,
            '75-80': 9,
            '80-85': 5,
            '85-90': 1
        }
    }
}

function getMapData1() {
    return {
        hourlyReport: {
            weekdaysAverage: {
                '00:15': {
                    Eastbound_Peds: {
                        Primary: 2.3, //23 for 10 days
                        Adult: 15, //150 for 10 days
                        Preschool: 12 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 2.3,
                        Adult: 11,
                        Preschool: 10
                    }
                },
                '00:30': {
                    Eastbound_Peds: {
                        Primary: 2.3, //23 for 10 days
                        Adult: 15, //150 for 10 days
                        Preschool: 12 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 2.3,
                        Adult: 11,
                        Preschool: 10
                    }
                },
                '00:45': {
                    Eastbound_Peds: {
                        Primary: 2.3, //23 for 10 days
                        Adult: 15, //150 for 10 days
                        Preschool: 12 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 2.3,
                        Adult: 11,
                        Preschool: 10
                    }
                },
                '01:00': {
                    Eastbound_Peds: {
                        Primary: 2.3, //23 for 10 days
                        Adult: 15, //150 for 10 days
                        Preschool: 12 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 2.3,
                        Adult: 11,
                        Preschool: 10
                    }
                },
            },
            weekendAverage: {
                '00:15': {
                    Eastbound_Peds: {
                        Primary: 7, //23 for 10 days
                        Adult: 12, //150 for 10 days
                        Preschool: 15 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 5,
                        Adult: 11.8,
                        Preschool: 1
                    }
                },
                '00:30': {
                    Eastbound_Peds: {
                        Primary: 5, //23 for 10 days
                        Adult: 5, //150 for 10 days
                        Preschool: 5 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 6,
                        Adult: 6,
                        Preschool: 6
                    }
                },
                '00:45': {
                    Eastbound_Peds: {
                        Primary: 7, //23 for 10 days
                        Adult: 7, //150 for 10 days
                        Preschool: 7 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 8,
                        Adult: 8,
                        Preschool: 8
                    }
                },
                '01:00': {
                    Eastbound_Peds: {
                        Primary: 9, //23 for 10 days
                        Adult: 9, //150 for 10 days
                        Preschool: 9 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 10,
                        Adult: 10,
                        Preschool: 10
                    }
                },
            },
            averageDailyTraffic: {
                '00:15': {
                    Eastbound_Peds: {
                        Primary: 11, //23 for 10 days
                        Adult: 11, //150 for 10 days
                        Preschool: 11 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 12,
                        Adult: 12,
                        Preschool: 12
                    }
                },
                '00:30': {
                    Eastbound_Peds: {
                        Primary: 13, //23 for 10 days
                        Adult: 13, //150 for 10 days
                        Preschool: 13 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 14,
                        Adult: 14,
                        Preschool: 14
                    }
                },
                '00:45': {
                    Eastbound_Peds: {
                        Primary: 15, //23 for 10 days
                        Adult: 15, //150 for 10 days
                        Preschool: 15 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 16,
                        Adult: 16,
                        Preschool: 16
                    }
                },
                '01:00': {
                    Eastbound_Peds: {
                        Primary: 17, //23 for 10 days
                        Adult: 17, //150 for 10 days
                        Preschool: 17 //120 for 10 days
                    },
                    Westbound_Peds: {
                        Primary: 18,
                        Adult: 18,
                        Preschool: 18
                    }
                },
            },
        },
        volumeReport: {
            averageDailyTraffic: {
                fifteenMinutes: {
                    '00:00': {
                        12: {
                            Truck: 2.3, //23 for 10 days
                            Car: 15, //150 for 10 days
                            Pedestrian: 12 //120 for 10 days
                        },
                        13: {
                            Truck: 1.2,
                            Car: 11.5,
                            Pedestrian: 1.16
                        }
                    },
                    '00:15': {
                        12: {
                            Truck: 3.3, //23 for 10 days
                            Car: 12.3, //150 for 10 days
                            Pedestrian: 10.4 //120 for 10 days
                        },
                        13: {
                            Truck: 2.3,
                            Car: 4.5,
                            Pedestrian: 8.9
                        }
                    },
                },
                hourly: {
                    '00:00': {
                        12: {
                            Truck: 2.3, //23 for 10 days
                            Car: 15, //150 for 10 days
                            Pedestrian: 12 //120 for 10 days
                        },
                        13: {
                            Truck: 12,
                            Car: 11,
                            Pedestrian: 10
                        }
                    },
                    '01:00': {
                        12: {
                            Truck: 2.3, //23 for 10 days
                            Car: 15, //150 for 10 days
                            Pedestrian: 12 //120 for 10 days
                        },
                        13: {
                            Truck: 12,
                            Car: 11,
                            Pedestrian: 10
                        }
                    },
                }
            },
            date: {
                '21.03.2024.': {
                    fifteenMinutes: {
                        '00:00': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                        '00:15': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            },
                            14: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                    },
                    hourly: {
                        '00:00': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                        '01:00': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                    }
                },
                '22.03.2024.': {
                    fifteenMinutes: {
                        '00:00': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                        '00:15': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                    },
                    hourly: {
                        '00:00': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                        '01:00': {
                            12: {
                                Truck: 23,
                                Car: 150,
                                Pedestrian: 120
                            },
                            13: {
                                Truck: 120,
                                Car: 110,
                                Pedestrian: 100
                            }
                        },
                    }
                },
            }
        },
        speedReport: {
            averageDailyTraffic: {
                fifteenMinutes: {
                    '00:00-00:15': {
                        12: {
                            speed: 50,
                        },
                        13: {
                            speed: 45
                        }
                    },
                    '00:15-00:30': {
                        12: {
                            speed: 50,
                        },
                        13: {
                            speed: 50,
                        }
                    },
                },
                hourly: {
                    '00:00-01:00': {
                        12: {
                            speed: 50,
                        },
                        13: {
                            speed: 50,
                        }
                    },
                    '01:00-02:00': {
                        12: {
                            speed: 50,
                        },
                        13: {
                            speed: 50,
                        }
                    },
                }
            },
            date: {
                '21.03.2024.': {
                    fifteenMinutes: {
                        '00:00-00:15': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                        '00:15-00:30': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                    },
                    hourly: {
                        '00:00-01:00': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                        '01:00-02:00': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                    }
                },
                '22.03.2024.': {
                    fifteenMinutes: {
                        '00:00-00:15': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                        '00:15-00:30': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                    },
                    hourly: {
                        '00:00-01:00': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                        '01:00-02:00': {
                            12: {
                                speed: 50,
                            },
                            13: {
                                speed: 50,
                            }
                        },
                    }
                },
            }
        },
        speedDistributionReport: {
            '0-5': 7,
            '5-10': 8,
            '10-15': 12,
            '15-20': 15,
            '20-25': 17,
            '25-30': 22,
            '30-35': 30,
            '35-40': 45,
            '40-45': 45,
            '45-50': 47,
            '50-55': 49,
            '55-60': 35,
            '60-65': 32,
            '65-70': 20,
            '70-75': 12,
            '75-80': 9,
            '80-85': 5,
            '85-90': 1
        }
    }
}

function createHourlyReport(aiKitData, serverConfigurationData, classTypesFilter) {
    let configurationData = JSON.parse(JSON.stringify(serverConfigurationData));
    let sumOfEventsByClassType = {}
    let sumOfEvents = 0
    configurationData.items.forEach(item => {
        let sum = 0;
        item.data = {
            name: item.wayLabel,
            events: sum,
            classTypes: {},
            dates: 'Dates'
        }
        if (aiKitData[item.wayKitLabel]) {
            console.log('DATA EXISTS', aiKitData[item.wayKitLabel])
            Object.keys(aiKitData[item.wayKitLabel]).forEach(key => {
                if (classTypesFilter.includes(key)) {
                    //check week day

                    item.data.events += aiKitData[item.wayKitLabel][key].length
                    item.data.classTypes[key] = aiKitData[item.wayKitLabel][key].length
                    if (sumOfEventsByClassType[key]) {
                        sumOfEventsByClassType[key] = sumOfEventsByClassType[key] + aiKitData[item.wayKitLabel][key].length
                    } else {
                        sumOfEventsByClassType[key] = aiKitData[item.wayKitLabel][key].length
                    }

                    sumOfEvents += aiKitData[item.wayKitLabel][key].length
                }
            })
        }
    })

}

function get15HoursMap() {
    return {
        0: '00:00',
        1: '00:15',
        2: '00:30',
        3: '00:45',
        4: '01:00',
        5: '01:15',
        6: '01:30',
        7: '01:45',
        8: '02:00',
        9: '02:15',
        10: '02:30',
        11: '02:45',
        12: '03:00',
        13: '03:15',
        14: '03:30',
        15: '03:45',
        16: '04:00',
        17: '04:15',
        18: '04:30',
        19: '04:45',
        20: '05:00',
        21: '05:15',
        22: '05:30',
        23: '05:45',
        24: '06:00',
        25: '06:15',
        26: '06:30',
        27: '06:45',
        28: '07:00',
        29: '07:15',
        30: '07:30',
        31: '07:45',
        32: '08:00',
        33: '08:15',
        34: '08:30',
        35: '08:45',
        36: '09:00',
        37: '09:15',
        38: '09:30',
        39: '09:45',
        40: '10:00',
        41: '10:15',
        42: '10:30',
        43: '10:45',
        44: '11:00',
        45: '11:15',
        46: '11:30',
        47: '11:45',
        48: '12:00',
        49: '12:15',
        50: '12:30',
        51: '12:45',
        52: '13:00',
        53: '13:15',
        54: '13:30',
        55: '13:45',
        56: '14:00',
        57: '14:15',
        58: '14:30',
        59: '14:45',
        60: '15:00',
        61: '15:15',
        62: '15:30',
        63: '15:45',
        64: '16:00',
        65: '16:15',
        66: '16:30',
        67: '16:45',
        68: '17:00',
        69: '17:15',
        70: '17:30',
        71: '17:45',
        72: '18:00',
        73: '18:15',
        74: '18:30',
        75: '18:45',
        76: '19:00',
        77: '19:15',
        78: '19:30',
        79: '19:45',
        80: '20:00',
        81: '20:15',
        82: '20:30',
        83: '20:45',
        84: '21:00',
        85: '21:15',
        86: '21:30',
        87: '21:45',
        88: '22:00',
        89: '22:15',
        90: '22:30',
        91: '22:45',
        92: '23:00',
        93: '23:15',
        94: '23:30',
        95: '23:45',
        96: '00:00'
    }
}
function getMiniChartMap() {
    return {
        '00:15':0,
        '00:30':0,
        '00:45':0,
        '01:00':0,
        '01:15':0,
        '01:30':0,
        '01:45':0,
        '02:00':0,
        '02:15':0,
        '02:30':0,
        '02:45':0,
        '03:00':0,
        '03:15':0,
        '03:30':0,
        '03:45':0,
        '04:00':0,
        '04:15':0,
        '04:30':0,
        '04:45':0,
        '05:00':0,
        '05:15':0,
        '05:30':0,
        '05:45':0,
        '06:00':0,
        '06:15':0,
        '06:30':0,
        '06:45':0,
        '07:00':0,
        '07:15':0,
        '07:30':0,
        '07:45':0,
        '08:00':0,
        '08:15':0,
        '08:30':0,
        '08:45':0,
        '09:00':0,
        '09:15':0,
        '09:30':0,
        '09:45':0,
        '10:00':0,
        '10:15':0,
        '10:30':0,
        '10:45':0,
        '11:00':0,
        '11:15':0,
        '11:30':0,
        '11:45':0,
        '12:00':0,
        '12:15':0,
        '12:30':0,
        '12:45':0,
        '13:00':0,
        '13:15':0,
        '13:30':0,
        '13:45':0,
        '14:00':0,
        '14:15':0,
        '14:30':0,
        '14:45':0,
        '15:00':0,
        '15:15':0,
        '15:30':0,
        '15:45':0,
        '16:00':0,
        '16:15':0,
        '16:30':0,
        '16:45':0,
        '17:00':0,
        '17:15':0,
        '17:30':0,
        '17:45':0,
        '18:00':0,
        '18:15':0,
        '18:30':0,
        '18:45':0,
        '19:00':0,
        '19:15':0,
        '19:30':0,
        '19:45':0,
        '20:00':0,
        '20:15':0,
        '20:30':0,
        '20:45':0,
        '21:00':0,
        '21:15':0,
        '21:30':0,
        '21:45':0,
        '22:00':0,
        '22:15':0,
        '22:30':0,
        '22:45':0,
        '23:00':0,
        '23:15':0,
        '23:30':0,
        '23:45':0,
        '00:00':0
    }
}

function isAllowed(me, requestedPerm) {
    let permNames = []
    if (me && me.permissions && me.permissions.length > 0) {
        permNames = me.permissions.map(perm => perm.name)
    }
    if (permNames.includes(requestedPerm)) {
        return true
    }
    return false
}

module.exports = {
    risks,
    calculateRiskSumFromWeeklyReport,
    capitalizeFirstLetter,
    calculateToDcaFromWeeklyReport,
    getRiskRatingClass,
    calculateSimpleRisk,
    getMarkerClass,
    getSeverityRatingScore,
    getDate,
    getTime,
    getDateISOIgnoreTimezone,
    getFormattedHours,
    getClassTypes,
    getFormatted15Minutes,
    getFormattedHourData,
    getSpeedDistributionData,
    getMapData,
    createHourlyReport,
    getShortTime,
    get15HoursMap,
    getMapData1,
    getShortDate,
    getSubClassType,
    getHoursList,
    getMiniChartMap,
    getMatrixReport,
    isAllowed,
    appName,
    Role,
    Apps
}